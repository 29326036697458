.product-card {
    position: relative;
    overflow: hidden;
  }
  
  .product-card::before {
    content: "";
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(150, 234, 212, 0.5); /* Light green */
    transition: bottom 0.4s ease-in-out;
  }
  
  .product-card:hover::before {
    bottom: 0;
  }
  