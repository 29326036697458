
.collapse-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
  }
  
  .collapse-content.open {
    max-height: 100px;
    opacity: 1;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px;
    }
    .col-md-6 {
      text-align: center;
    }
  }
  