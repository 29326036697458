.banner-text {
    background: rgba(255, 255, 255, 0.1); /* Slight transparency */
    backdrop-filter: blur(10px);
    border-radius: 10px;
    max-width: 600px;
    height: 400px;
    padding: 20px;
    position: relative;
    text-align: justify;
    transition: transform 0.3s ease-in-out;
  }
  
  

.banner-image {
    width: 100%;
    max-width: 600px;
    height: 400px;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .banner-text:hover {
    box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.2);
  }
  
  /* Title Styling Like Product Design */
  .banner-title {
    margin: 0 auto 20px;
    background-color: #009688;
    color: white;
    padding: 15px;
    width: calc(100% + 100px);
    position: relative;
    left: -50px;
    text-align: center;
    font-size: 24px;
}

/* Bottom Triangles */
.banner-title::before, 
.banner-title::after {
    content: "";
    position: absolute;
    bottom: -30px;
    border-width: 15px;
    border-style: solid;
}

.banner-title::before {
    left: 0;
    border-color: #02766b #02766b transparent transparent;
}

.banner-title::after {
    right: 0;
    border-color: #02766b transparent transparent #02766b;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .banner-title {
        width: calc(100% + 20px); /* Reduce extra width */
        left: -10px; /* Adjust positioning */
        padding: 10px; /* Reduce padding */
        font-size: 18px; /* Smaller font size */
    }

    .banner-title::before, 
    .banner-title::after {
        bottom: -20px; /* Adjust triangle position */
        border-width: 10px; /* Reduce triangle size */
    }

 
}



  .hover-effect {
    transition: filter 0.3s ease-in-out;
  }


  /* Smooth transition effect */
.transition-effect {
    transition: transform 0.3s ease-in-out;
  }
  
  /* Scale image slightly on hover */
  .img-container:hover .transition-effect {
    transform: scale(1.05);
  }
  
  /* Hide button initially */
  .read-more-btn {
    bottom: -50px;
    opacity: 0;
    transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  /* Show button at bottom on hover */
  .img-container:hover .read-more-btn {
    bottom: 10px;
    opacity: 1;
  }
  

  /* Image hover effect */
.img-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  /* Overlay that slides up */
  .hover-overlay {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(220, 53, 69, 0.8); 
    /* background: #86e2c8; */
    transition: bottom 0.5s ease-in-out;
  }
  
  /* Image hover scale effect */
  .img-container:hover .hover-overlay {
    bottom: 0;
  }
  
  /* Read More Button */
  .read-more-btn {
    bottom: -50px;
    opacity: 0;
    transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  /* Show button when hovering */
  .img-container:hover .read-more-btn {
    bottom: 20px;
    opacity: 1;
  }
  
  
  .modern-btn {
    display: inline-block;
    padding: 8px 30px;
    background: linear-gradient(90deg, #00b3b3, #f2fcfe);
    background-size: 200% 100%;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border-radius: 20px;
    text-decoration: none;
    transition: background-position 0.5s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0, 123, 255, 0.3);
}

.modern-btn:hover {
    background-position: 100% 0;
    transform: translateY(-3px);
    box-shadow: 0px 8px 20px rgba(0, 123, 255, 0.5);
}

